@import '../../App.scss';
.modal-enter {
	opacity: 0.01;

	.modal-view {
		transform: scale(0.9) translateY(20%);
	}
}
.modal-enter-active {
	opacity: 1;
	transition: all 200ms ease-out;

	.modal-view {
		transform: scale(1) translateY(0%);
	}
}
.modal-exit {
	opacity: 1;

	.modal-view {
		transform: scale(1) translateY(0%);
	}
}
.modal-exit-active {
	opacity: 0.01;
	transition: all 200ms ease-out;

	.modal-view {
		transform: scale(0.9) translateY(20%);
	}
}
.container--tracking-cards {
	margin: 2rem 2rem 8rem 2rem;

	.text--all-done {
		width: 95vw;
		max-width: 20rem;
		height: 4rem;
		background-color: $mediumLightGreen;
		border-radius: 1rem;
	}

	.container--controls {
		width: 100%;
		color: $darkGray;

		.p-inputswitch {
			&.p-inputswitch-checked {
				.p-inputswitch-slider {
					background: $mediumGreen;
					filter: opacity(100%);
				}
			}

			.p-inputswitch-slider {
				background: $mediumDarkGreen;
				filter: opacity(60%);
			}
		}
	}
	.container--cards {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.p-card {
			width: 95vw;
			max-width: 20rem;
			height: 20rem;
			overflow: hidden;
			box-shadow: 0 0 2rem rgba(0, 0, 0, 0.281);
			.p-card-header {
				background-color: $mediumGreen;
				border-bottom: 0.3rem solid $mediumDarkGreen !important;

				.header {
					color: white;
					text-transform: capitalize;
					text-shadow: 2px 2px $transparentMediumGray;
					font-size: 1.5rem;
					height: 3rem;
					.btn--friends {
						color: white;
						.p-button-icon {
							font-size: 1.3rem;
						}
					}
					.text {
						max-width: 85%;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					.p-checkbox {
						height: 100%;
						display: flex;
						align-items: center;
						.p-checkbox-box {
							margin-left: -1rem !important;
							width: 2rem;
							height: 2rem;
							// height: 3rem;
							.pi {
								font-size: 1.5rem;
							}
						}
					}
				}
			}

			.p-card-body {
				background-color: $backgroundTan;
				height: 100%;
				padding: 0;
				.p-card-content {
					padding: 0.3rem;
					height: 100%;

					.container--card-content {
						&.achievement {
							height: 85%;

							.details {
								font-size: 0.8rem;
							}
						}

						&.reaction {
							.source {
								font-size: 1.3rem;
							}
							.villager-info {
								background-color: $mediumBlue;
								border-radius: 1rem;
								color: white;
								width: 90%;
								max-height: 5rem;
								overflow: hidden;
								text-overflow: ellipsis;
								.villager {
									display: -webkit-box;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
									overflow: hidden;
									text-overflow: ellipsis;
									max-height: 4rem;
									font-size: 1.2rem;
								}
							}
						}

						.container--checkboxes {
							width: 100%;
							height: 100%;
							.p-checkbox {
								width: 3rem;
								height: 100%;
								display: flex;
								align-items: center;
								border-radius: 50%;
								&.p-checkbox-checked {
									content: url('../../assets/images/stamp.jpg');
								}
								.p-checkbox-box {
									border-radius: 50%;
									width: 100%;
									height: 3rem;

									.pi {
										font-size: 1.5rem;
									}
								}
							}
						}

						&:not(.fossil) {
							.icon {
								max-width: 5rem;
							}
						}
						&.fossil {
							height: 85%;

							img {
								max-height: 10rem;
								object-fit: contain;
							}
						}
					}
				}

				.container--card-footer {
					div {
						width: 45%;

						.available-badge {
							font-weight: normal;
							height: 2rem;
							width: 90%;
							font-size: 1rem;
						}
					}
				}

				.container--card-info {
					text-align: right;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0.5rem 1rem;
					text-transform: capitalize;
					width: 100%;
					border-radius: 1rem;
					background-color: $mediumLightGreen;
					white-space: nowrap;
					overflow: hidden;
					margin-bottom: 1rem;

					.left {
						text-align: left;
					}

					.pi {
						font-size: 1.3rem;
					}

					&.line-break {
						white-space: normal;
					}

					&.top {
						margin-bottom: 0;
						height: 3.5rem;

						.pi {
							font-size: 1.5rem;
						}
					}
				}
			}
		}
	}
}
.modal--achievement {
	width: 100%;
	max-width: 40rem;

	.text--reqs,
	.text--unlocked {
		font-size: 1.2rem;
		font-weight: 600;
	}
	.text--desc {
		font-style: italic;
	}
}
.modal--art {
	text-transform: capitalize;

	.container--art-modal {
		width: 100%;
		max-width: 50rem;

		&.fake {
			max-width: 100rem;

			.note {
				text-transform: none;
				font-size: 1.4rem;
			}

			.link {
				text-transform: none;
				font-size: 1.4rem;
			}
		}

		.spacer {
			width: 1rem;
			height: 1rem;
		}

		.comparison {
			width: 100%;

			&.hidden {
				visibility: hidden;
			}

			.art {
				width: 80vw;
				// max-width: 30%;
			}
		}

		img {
			width: 100%;
			max-height: 60vh;
			object-fit: contain;
		}
	}
}
.page--totals {
	padding-bottom: 10rem;

	.p-progressbar {
		width: 95vw;
		max-width: 40rem;
		height: 3rem;
		border: 2px solid $lightGray;

		.p-progressbar-label {
			display: flex;
			align-items: center;
			justify-content: center;
			color: black;
			font-size: 1.5rem;
		}
	}
	.text.overall {
		color: $mediumGray;
		font-size: 1.5rem;
	}
	.container--totals {
		width: 95vw;
		// max-width: 80rem;

		.container--card-content {
			background-color: $mediumLightGreen;
			border: 0.7rem solid $backgroundColor;
			border-radius: 3rem;
			width: 95vw;
			max-width: 20rem;
			cursor: pointer;

			.text {
				font-size: 1.5rem;
				color: $mediumGray;
				&.percentage {
					font-size: 1.7rem;
				}
			}
		}
	}
}
.modal--friends-progress {
	width: 95vw;
	max-width: 25rem;
	min-height: 10rem;
	max-height: 80vh;

	.p-dialog-header {
		text-transform: capitalize;
	}
	.content-link {
		font-weight: bold;
		color: $mediumDarkGreen;
		cursor: pointer;

		&:hover {
			text-decoration: underline !important;
		}
	}
}
