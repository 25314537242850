@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@500&display=swap');

$backgroundColor: #b4d3cd;
$darkGray: rgb(33, 33, 33);
// $mediumGreen: #66ac83;
$mediumGreen: #499468;
$mediumLightGreen: #cbecd9;
$mediumDarkGreen: #62a57d;
$mediumBlue: #71b6f7;
$mediumGray: #2c2f31;
$lightGray: #4b4f52;
$gainzGreen: #44e744;
$linkBlue: #1d9bf0;
$errorRed: #eb4d4d;
$warnYellow: #abb42d;
$darkErrorRed: #eb4d4d;
$transparentMediumGray: #00000028;
$transparentDarkGray: #00000023;
$discord: rgb(147, 146, 233);
$backgroundTan: #f5f5dd;

.App *:not(.pi) {
	font-family: 'Baloo 2', cursive !important;
}
.App {
	min-height: 100vh;
	width: 100%;
	max-width: 100vw;
	padding: 0;
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient($transparentMediumGray, $transparentDarkGray),
		url('./assets/images/pattern.jpg');
	background-attachment: fixed;
	background-position: center;
	overflow-x: hidden;
}
.App > :not(.container--footer):not(.container--navbar) {
	min-height: 80vh;
}
html {
	background: linear-gradient($transparentMediumGray, $transparentDarkGray),
		url('./assets/images/pattern.jpg');
	background-color: $backgroundColor;
	max-width: 100vw;
}

// custom globals

.text--center {
	text-align: center;
}

.p-tag-success {
	background-color: $mediumDarkGreen !important;
}
.p-tag-warning {
	background-color: $warnYellow !important;
}
